exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-index-js": () => import("./../../../src/pages/misc/index.js" /* webpackChunkName: "component---src-pages-misc-index-js" */),
  "component---src-pages-misc-mdx-slug-js": () => import("./../../../src/pages/misc/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-misc-mdx-slug-js" */),
  "component---src-pages-software-engineering-index-js": () => import("./../../../src/pages/software-engineering/index.js" /* webpackChunkName: "component---src-pages-software-engineering-index-js" */),
  "component---src-pages-software-engineering-mdx-slug-js": () => import("./../../../src/pages/software-engineering/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-software-engineering-mdx-slug-js" */),
  "component---src-pages-web-development-index-js": () => import("./../../../src/pages/web-development/index.js" /* webpackChunkName: "component---src-pages-web-development-index-js" */),
  "component---src-pages-web-development-mdx-slug-js": () => import("./../../../src/pages/web-development/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-web-development-mdx-slug-js" */)
}

